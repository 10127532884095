import { SanityImageSource } from "@sanity/image-url/lib/types/types";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import classnames from "classnames";
import React from "react";

import { CONSTANTS } from "../../constants";
import { urlFor } from "../../util/urlFor";
import { SimpleButton } from "../simpleButton";
import {
  backgroundImage,
  bookPreview,
  infoStyle,
  moreStyle,
  titleStyle,
} from "./bookPreview.style.css";

type Props = {
  className?: string;
  bookData: Queries.bookPreviewFragment;
};

const getBackgroundUrl = (bgImage: Queries.SanityRasterImage) => {
  if (!bgImage) return null;
  return urlFor(bgImage.file as SanityImageSource).url();
};

export const BookPreview = ({ bookData, className }: Props) => {
  const { image, title, abstract, slug } = bookData;

  const classNames = classnames(className, bookPreview);

  const imgUrl = getBackgroundUrl(image as Queries.SanityRasterImage);

  const inlineVars = assignInlineVars({
    [backgroundImage]: `url("${imgUrl}")`,
  });

  return (
    <article className={classNames} style={inlineVars}>
      <h1 className={titleStyle}>{title}</h1>
      <p className={infoStyle}>{abstract}</p>
      <nav className={moreStyle}>
        <SimpleButton
          to={`${CONSTANTS.PATH.BOOKS}/${slug.current}`}
          title={`See more information about ${title}`}
        >
          View Book
        </SimpleButton>
      </nav>
    </article>
  );
};
