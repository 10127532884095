import { graphql, useStaticQuery } from "gatsby";

export const useFeaturedBooks = (): Queries.bookPreviewFragment[] => {
  const result: Queries.FeaturedBooksQuery = useStaticQuery(query);
  return result.featuredBooks.nodes as Queries.bookPreviewFragment[];
};

const query = graphql`
  query FeaturedBooks {
    featuredBooks: allSanityBook(
      filter: { isFeatured: { eq: true } }
      sort: { order: ASC, fields: title }
    ) {
      nodes {
        ...bookPreview
      }
    }
  }
`;
