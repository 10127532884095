import { useStaticQuery } from "gatsby";
import { graphql } from "gatsby";
import * as React from "react";

import { ArticlePreview } from "../components/article";
import { BookPreview } from "../components/bookPreview";
import { LayoutGroup } from "../components/layoutGroup";
import PageLayout from "../components/pageLayout";
import { PageSection } from "../components/pageSection";
import { SimpleButton } from "../components/simpleButton";
import { dark } from "../css/styles/styles.css";
import { useFeaturedBooks } from "../hooks/useFeaturedBooks";
import { useLatestArticles } from "../hooks/useLatestArticles";
import { HeadTemplate } from "../templates/headTemplate";

const IndexPage = () => {
  const articles = useLatestArticles();
  const books = useFeaturedBooks();
  const { info } = useStaticQuery(query);

  const { siteTitle, siteDescription } = info;

  const articlePreviews = articles.map((article) => (
    <ArticlePreview articleData={article} key={article.slug.current} />
  ));

  const bookPreviews = books.map((book) => (
    <BookPreview bookData={book} key={book.slug.current} />
  ));

  return (
    <PageLayout>
      <PageSection className={dark}>
        <h1>{siteTitle || "Jen Breach"}</h1>
        <LayoutGroup variants={{ width: "bodyText" }}>
          {siteDescription && <p>{siteDescription}</p>}
        </LayoutGroup>
        <SimpleButton to="about/">More About Jen</SimpleButton>
      </PageSection>

      <PageSection backgroundStyle={{ angled: "leftHigh" }}>
        <h1>Featured Books</h1>
        <LayoutGroup variants={{ display: "grid" }}>{bookPreviews}</LayoutGroup>
      </PageSection>
    </PageLayout>
  );
};

export const query = graphql`
  query SiteIntro {
    info: sanitySiteSettings {
      siteTitle: title
      siteDescription: description
    }
  }
`;

export default IndexPage;

export const Head: React.FC = () => HeadTemplate({});
